var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reset-password-page"
  }, [_c('auth-layout', [_c('p', [_vm._v("الرجاء التواصل مع المسؤول لطلب كلمة مرور مؤقتة جديدة")]), _c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/zadi/request-new-password.png")
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }