<template>
  <div class="reset-password-page">
    <auth-layout>
      <p>الرجاء التواصل مع المسؤول لطلب كلمة مرور مؤقتة جديدة</p>
      <div class="image">
        <img src="../../../../../public/assets/images/website/zadi/request-new-password.png" />
      </div>
    </auth-layout>
  </div>
</template>

<script>
import AuthLayout from '../../layout/AuthLayout'

export default {
  metaInfo: {
    title: "مشروع زادي | طلب كلمة مرور جديدة",
  },
  components: { AuthLayout },
};
</script>

<style scoped>
p {
  color: #9A9A9A;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  margin-top: 10px;
}
.image {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image img {
  max-width: 320px;
  width: 100%;
}
</style>